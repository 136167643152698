import React from "react"
import styled, { css } from "styled-components"
import Image from "gatsby-image"
import Grid from "../lib/grid"

const Head = styled.div`
  color: #4e5562;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1.6rem;
  margin-top: ${props => props.theme.verticalSpacing(1)};
  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    line-height: 2rem;
  }
`

const Content = styled.div`
  color: ${props => props.theme.oilslick};
  margin: 4px 0;
  font-size: 1.2rem;
  line-height: 1.6rem;
  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`

const Position = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`

const CompanyLogo = styled.img`
  height: 2.4rem;
  margin-top: 4px;
  margin-bottom: ${props => props.theme.verticalSpacing(0)};
  align-self: flex-start;
  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    margin-top: ${props => props.theme.verticalSpacing(0.5)};
  }
`

const Name = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: bold;
  color: ${props => props.theme.oilslick};
  margin-top: 4px;
  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    margin-top: ${props => props.theme.verticalSpacing(0.25)};
  }
`

const Row = styled.div``

const Headshot = ({ img, children }) => (
  <Grid.Col sm={12} md={6} smBottom={2} lgBottom={3}>
    <div
      css={css`
        border-radius: 8px;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        ${"" /* @media only screen and (max-width: ${props => props.theme.tablet - 1}px) {
          flex-direction: row;
          align-items: flex-start;
        } */}
      `}
    >
      <div
        css={css`
          margin-right: 20px;
        `}
      >
        <Image
          css={css`
            border-radius: 8px;
            width: 80px !important;
            height: 80px !important;

            @media (min-width: ${props => props.theme.desktop}px) {
            width: 150px !important;
            height: 150px !important;
            }
          `}
          fixed={img}
          alt="headshot"
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        {children}
      </div>
    </div>
  </Grid.Col>
)

export default {
  Headshot,
  Row,
  Name,
  CompanyLogo,
  Position,
  Content,
  Head,
}
