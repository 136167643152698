import React from "react"
import { graphql, Link } from "gatsby"
import umbelLogo from "../images/logo_umbel.svg"
import msgLogo from "../images/logo_msg.svg"
import hsbcLogo from "../images/logo_hsbc.svg"
import nbcuLogo from "../images/logo_nbcu.svg"
import amsLogo from "../images/logo_ams.png"
import leadersLogo from "../images/logo_leaders.jpeg"
import Headshot from "../components/archive/index/headshots"
import Layout from "../components/archive/layout"
import SEO from "../components/archive/seo"
import Grid from "../components/archive/lib/grid"
import Jumbotron from "../components/archive/jumbotron"
import styled, { css } from "styled-components"
import { HR } from "./careers"

const DesktopBR = styled.br`
  display: none;
  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    display: block;
  }
`

const About = ({ data }) => {
  return (
    <Layout initialHeaderLight={true}>
      <SEO title="About" />
      <Grid>
        <Grid.Row>
          <Grid.Col>
            <Jumbotron.Title css={{ marginTop: "6rem" }}>
              Data Ownership Transformed
            </Jumbotron.Title>
            <Description>
              The global sports industry is facing the greatest opportunity for
              revenue creation in its history. <DesktopBR />
              To compete, Sports Organizations must know their fan base and turn
              that knowledge into revenue.
            </Description>
            <Description>
              We provide a turnkey solution for rights holders to uncork the
              intelligence gridlock within their organization and empower their
              businesses for a 3.0 world.
            </Description>
            <HR />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Layout>
  )
}

export default About

const Description = styled.p`
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 0;
  font-weight: 300;
`

export const query = graphql`
  query {
    tom: file(relativePath: { eq: "headshot_tom.jpg" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    nick: file(relativePath: { eq: "headshot_nick.jpg" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    dave: file(relativePath: { eq: "headshot_dave.jpg" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    giles: file(relativePath: { eq: "headshot_giles.jpg" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    noa: file(relativePath: { eq: "headshot_noa.jpeg" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    martin: file(relativePath: { eq: "headshot_martin.jpeg" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    corey: file(relativePath: { eq: "headshot_corey.jpg" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`
