import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/archive/layout"
import SEO from "../components/archive/seo"
import Grid from "../components/archive/lib/grid"
import Jumbotron from "../components/archive/jumbotron"
import styled from "styled-components"

const Careers = ({ data }) => {
  return (
    <Layout initialHeaderLight={true}>
      <SEO title="Careers" />
      <Grid>
        <Grid.Row>
          <Grid.Col>
            <Jumbotron.Title css={{ marginTop: "6rem" }}>
              Turn Data into Discovery
            </Jumbotron.Title>
            <Description>
              We’re looking for world-class talent ready to think big and make
              data accessible to all.
            </Description>
            <Description>
              Data is everywhere, and so are we. We’re a borderless company
              tuned specifically for the Sports industry, motivated by turning
              data into a discovery platform. Join us as we continue to scale
              internationally, expand our partnerships, and build new services
              for the Sports and Entertainment industry.
            </Description>
            <HR />
            <CareerList>
              {data?.allMarkdownRemark?.edges?.map(item => (
                <li>
                  <CareerItem to={item.node.fields.slug}>
                    <span>{item.node.frontmatter.title}</span>
                    <RightChevron />
                  </CareerItem>
                </li>
              ))}
            </CareerList>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Layout>
  )
}

export default Careers

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { type: { eq: "careers" } }
        frontmatter: { active: { eq: true } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

const Description = styled.p`
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 0;
  font-weight: 300;
`

export const HR = styled.hr`
  border: none;
  background: none;
  margin: ${props => props.theme.verticalSpacing(2)} 0;
  border-top: 1px solid ${props => props.theme.oilslick30};
`

const CareerList = styled.ul`
  list-style-type: none;
  padding: 0;
  padding-bottom: 6rem;
`

const CareerItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: inherit;
  text-decoration: none;
  font-weight: bold;

  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 0;
  font-weight: 400;
  padding: 1rem 1rem 1rem 0;

  background: transparent;

  transition: all 0.2s ease-out;

  &:hover {
    padding-left: 2rem;
    background: ${props => props.theme.oilslick20};
  }

  li:not(:last-of-type) & {
    border-bottom: 1px solid ${props => props.theme.oilslick30};
  }
`

const ColorSvg = styled.svg`
  fill: currentColor;
`
// TODO: use @tixsee/uniform
function RightChevron() {
  return (
    <ColorSvg viewBox="4 0.5 9 15" width="1rem">
      <path d="M4.346 1.116a1.25 1.25 0 00-.091 1.666l.091.102L9.463 8l-5.117 5.116a1.25 1.25 0 00-.091 1.666l.091.102a1.25 1.25 0 001.666.091l.102-.091L12.998 8 6.114 1.116a1.25 1.25 0 00-1.768 0z" />
    </ColorSvg>
  )
}
